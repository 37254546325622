import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Generator_zmj10b = () => {
  return (
    <div>
      <Navbar />
      <div>
        <h1 className="mt-5 pt-5">This is Oxygen Gennerator 10ltm</h1>
      </div>
      <Footer />
    </div>
  );
};

export default Generator_zmj10b;
