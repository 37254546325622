import React from "react";
import ReactTypingEffect from "react-typing-effect";

const ReactTypingEffectDemo = () => {
  return (
    <div>
      <ReactTypingEffect
        text={[
          "Oxygen Concentrators.",
          "Air Purifiers",
          "Smart Masks",
          "Oxygen Generators",
        ]}
      />

      <br />

      <ReactTypingEffect
        text={[
          "Oxygen Concentrators.",
          "Air Purifiers.",
          "Smart Masks.",
          "Oxygen Generators.",
        ]}
        cursorRenderer={(cursor) => <h1>{cursor}</h1>}
        displayTextRenderer={(text, i) => {
          return (
            <h1 className="fw-bold">
              {text.split("").map((char, i) => {
                const key = `${i}`;
                return (
                  <span
                    key={key}
                    style={i % 3 === 0 ? { color: "#ffffff8b" } : {}}
                  >
                    {char}
                  </span>
                );
              })}
            </h1>
          );
        }}
      />
    </div>
  );
};

export default ReactTypingEffectDemo;
