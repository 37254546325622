import React from "react";
import WhatsappImg from "../img/WhatsApp.png";

const Whatsapp = () => {
  return (
    <div className="whatsapp">
      <h1>
        <a
          href="https://api.whatsapp.com/send?phone=919717166877"
          target="blank"
        >
          <img
            src={WhatsappImg}
            alt="Whatsapp Image"
            // height="70px"
            // width="70px"
          />
        </a>
      </h1>
    </div>
  );
};

export default Whatsapp;
