import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/Oxy_5lt_touch_zm5w_1.JPG";
import Img2 from "../../img/product_Img/Oxy_5lt_touch_zm5w_2.JPG";
import Img3 from "../../img/product_Img/Oxy_5lt_touch_zm5w_3.JPG";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/oxy_5lt_ze5w/f_1.png";
import f_img2 from "../../img/product_img_features/oxy_5lt_ze5w/f_2.png";
import f_img3 from "../../img/product_img_features/oxy_5lt_ze5w/f_3.png";
import f_img4 from "../../img/product_img_features/oxy_5lt_zm5w_touch/f_4.png";

const Concentrator_zm5w = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Oxy"
            model="ZM-5W"
            productName="Oxygen Concentrator 5lpm"
            desc="Aura Oxy 5LPM Oxygen Concentrator comes with a sleek and ergonomic design, which makes it easier to store, move, and carry. It is one of the quietest and most energy-efficient devices with no filter to maintain, a durable metal oxygen outlet, and fewer moving parts. The device is designed to be compatible with all bottle styles and features an easy-to-use velcro closure. In addition to this, the interface panel has rotating flow meter knob for easy control. "
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="Medical Grade Oxygen"
                cardImg={f_img1}
                body="It produces medical grade oxygen ranging form 90% to 96% of high oxygen concentration. This helps in high rate of recovery of the patients."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Portable & sleek Design"
                cardImg={f_img2}
                body="The Design of the Aura Oxy is very sleek and it is also provided with high quality wheels so that it can be moved easily with no extra effort."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="In-Build Nabulizer"
                cardImg={f_img3}
                body="Aura Oxy comes with In-Build Nabuilizer. The nabulization kit is also provided with the device. It is very effective for asthama patients"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Touch screen Display"
                cardImg={f_img4}
                body="Aura Oxy comes with large HD Touch Display for better monitoring. You can monitor Real Time of oxygen concentration for adjustments as required. "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Oxygen Flow
              </div>
              <div className="col-lg-9 border-table p-3">
                5L/Min (can be adjusted 0.5L - 5L/Min)
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Oxygen Concentration{" "}
              </div>
              <div className="col-lg-9 border-table p-3">
                93% +- 3% (0-5L/Min)
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Outlet Pressure
              </div>
              <div className="col-lg-9 border-table p-3">30kPa - 70kPa</div>
              <div className="col-lg-3 border-table p-3 fw-bold">Alarm</div>
              <div className="col-lg-9 border-table p-3">
                Filter-dirty alarm, power faliure alarm, compressor overheating
                stop alarm, low flow rate alarm, low purity alarm
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">33 x 26 x 54 cm</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">22 kgs</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Concentrator_zm5w;
