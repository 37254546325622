import React from "react";
import {
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import myCardImg from "../img/product_Img/air_purifier_1-1.jpg";

const CardFeaturesProductPage = ({
  cardImg = myCardImg,
  body = "sunt alias, suscipit, unde, quidem commodi. Iure nihil id quam quisquam iusto inventore similique praesentium!",
  title = "Card Body",
}) => {
  return (
    <div>
      <Card className="product-page-features-card">
        <CardImg top width="100%" src={cardImg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">{title}</CardTitle>
          <CardText className="text-muted">{body}</CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardFeaturesProductPage;
