import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Concentrator_ze5w from "./Pages/product/concentrator_ze5w";
import Concentrator_zm5w from "./Pages/product/concentrator_zm5w";
import Canta from "./Pages/product/concentrator_canta";
import Concentrator_zy10zw from "./Pages/product/concentrator_zy10zw_10ltm";
import Generator_zmj10b from "./Pages/product/generator_zmj10b";
import Generator_zmj20b from "./Pages/product/generator_zmj20b";
import Air_small from "./Pages/product/air_purifier_small";
import Air_big from "./Pages/product/air_purifier_big";
import Smart_mask from "./Pages/product/smart_mask";
import New_dealer from "./Pages/dealer_form";
import Counter_top from "./Pages/product/counter_top";
import Wall_mount from "./Pages/product/wall_mount_5_stage";
const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      {/* Products Routes */}
      <Route exact path="/ao_ct_w" component={Counter_top} />
      <Route exact path="/ao_wm_w" component={Wall_mount} />
      <Route exact path="/ze5w" component={Concentrator_ze5w} />
      <Route exact path="/zm5w" component={Concentrator_zm5w} />
      <Route exact path="/canta" component={Canta} />
      <Route exact path="/zy10zw" component={Concentrator_zy10zw} />
      <Route exact path="/zmj10b" component={Generator_zmj10b} />
      <Route exact path="/zmj20b" component={Generator_zmj20b} />
      <Route exact path="/air_ozone" component={Air_small} />
      <Route exact path="/air_big" component={Air_big} />
      <Route exact path="/smart_mask" component={Smart_mask} />
      <Route path="/about" component={AboutUs} />
      <Route path="/contact" component={ContactUs} />
      <Route path="/new-dealer" component={New_dealer} />
    </Switch>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ReactDOM.render(routing, document.getElementById("root"));
