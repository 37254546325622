import React, { Component } from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function SimpleSlider({ img1, img2, img3 }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    autoplay: true,
  };
  return (
    <Slider {...settings}>
      <div>
        <img src={img1} alt="Img1" />
      </div>
      <div>
        <img src={img2} alt="Img1" />
      </div>
      <div>
        <img src={img3} alt="Img1" />
      </div>
    </Slider>
  );
}
