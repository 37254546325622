import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/mask_1.jpg";
import Img2 from "../../img/product_Img/mask_2.jpg";
import Img3 from "../../img/product_Img/mask_3.jpg";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/mask/f1.png";
import f_img2 from "../../img/product_img_features/mask/f2.png";
import f_img3 from "../../img/product_img_features/mask/f3.png";
import f_img4 from "../../img/product_img_features/mask/f4.png";

const Smart_mask = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Air"
            model="Aura Smart Mask"
            productName="Aura Air Smart Mask"
            desc="Aura Air mask is designed for comfortable protection against dust, pollen,fumes, common airborne irritants, and other non-oil based particles. It will help you breathe in a cleaner, healthier way.
            Our dust mask implements the design preventing the pollution mask from slipping off. "
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="Multi Layer Filters"
                cardImg={f_img1}
                body="It has got four layers of protection to purify the air and make it virus and bactria free upto 99.99% purity"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Integated with turbine"
                cardImg={f_img2}
                body="It is equiped with a two speed tirbine system which sucks the external air and the cool breese makes it comfortable to wear."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Comfortable Fit"
                cardImg={f_img3}
                body="The Aura Air Mask is designed in such a way that it fits all face sizes and is very comfortale to wear for long period of time."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Adjustable Strap"
                cardImg={f_img4}
                body="Aura Air Mask comes with a detachable strap. The strap is designed in such a way that it can be adjusted to all facial sizes "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">107 x 95 x 67 mm</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">80 grams</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Rated Current
              </div>
              <div className="col-lg-9 border-table p-3">240mA</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Rated Voltage
              </div>
              <div className="col-lg-9 border-table p-3">3.7V</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Charging Voltage
              </div>
              <div className="col-lg-9 border-table p-3">5V</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Charging Time
              </div>
              <div className="col-lg-9 border-table p-3">2 - 2.5 hours</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Working Time
              </div>
              <div className="col-lg-9 border-table p-3">5 - 7 hours</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Smart_mask;
