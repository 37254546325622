import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const DealerForm = () => {
  return (
    <div>
      <Navbar />
      <div className="form-new-dealer">
        <form
          className="container-fluid bg-custom1 dealer-form"
          action="https://formspree.io/f/xpzoraap"
          name="new-dealer"
          method="POST"
          data-netlify="true"
        >
          <div className="container bg-white card pb-4 p-4">
            <div className="row border rounded bg-light mb-5">
              <div className="col align-self-center ">
                <h2 className="text-center p-4 text-danger">
                  AMDL Dealership Enquiry form
                </h2>
              </div>
            </div>
            <h3 className="pt-4 pb-3">Your Basic Information</h3>
            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-4">
                <label for="name">
                  {" "}
                  Your Name <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  name="name"
                  id="name"
                  maxlength="30"
                  required
                />
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <label for="email">
                  Email Id<span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email id."
                  name="email"
                  id="email"
                />
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <label for="mobile-number">
                  Mobile Number<span>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="mobile-number"
                  id="mobile-number"
                  placeholder="Mobile no"
                  minlength="10"
                  maxlength="14"
                />
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <label for="adhar-number">
                  Aadhaar Card Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="adhar-number"
                  id="adhar-number"
                  placeholder="Adhar number"
                  required
                  maxlength="12"
                  minlength="12"
                />
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <label for="address">
                  Your Address <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Address."
                  name="address"
                  id="address"
                  required
                />
              </div>
            </div>

            <p>
              <a
                className="btn btn-primary pl-5 pr-5"
                data-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                NEXT
              </a>
            </p>
            <div className="collapse" id="collapseExample">
              <div className="card card-body">
                <h3 className="pt-4 pb-3">Business Information (Optional)</h3>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="company-name"> Company Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your company name"
                      name="company-name"
                      id="company-name"
                      maxlength="40"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="company-address">Company Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Address."
                      name="address"
                      id="company-address"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="gst-no">GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="gst-no"
                      id="gst-no"
                      placeholder="Gst no"
                      maxlength="15"
                      minlength="15"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="bisiness-sector">
                      Your Existing Business Sector
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="business-sector"
                      id="business-sector"
                      placeholder="Your Business Sector"
                      maxlength="30"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="business-years">
                      Number of years in Business
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="business-years"
                      id="business-years"
                      placeholder="Number of years in Business"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="turnover">
                      Turnover in last Financial year (in Lks)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="turnover"
                      id="turnover"
                      name="turnover"
                      placeholder="Turnover"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="soon">
                      How soon you want to setup your Dealership
                    </label>
                    <select name="soon" id="soon">
                      <option value="immediate">Immediate</option>
                      <option value="within 1month"> Within One Month</option>
                      <option value="within 3month">
                        {" "}
                        Within Three Months
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="capital">
                      Working capital to commit towards inventory (in lks)
                    </label>
                    <select name="capital" id="capital">
                      <option value="5-10">5-10 lks</option>
                      <option value="11-20"> 11-20 lks</option>
                      <option value="more than 20"> More than 20 lks</option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="state">
                      Geographical Area of Interest (State)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Interested State"
                      name="state"
                      id="state"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <label for="place">
                      Geographical Area of Interest (District/Place)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Interested District/place"
                      name="place"
                      id="place"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-success mt-3 mb-3 btn-block p-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default DealerForm;
