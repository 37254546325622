import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/5filter01.jpg";
import Img2 from "../../img/product_Img/5filter02.jpg";
import Img3 from "../../img/product_Img/5filter03.jpg";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/counter_top/01.png";
import f_img2 from "../../img/product_img_features/counter_top/02.png";
import f_img3 from "../../img/product_img_features/counter_top/03.png";
import f_img4 from "../../img/product_img_features/counter_top/04.png";

const Counter_top = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Oxy"
            model="AO-CT-W"
            productName="Countertop Alkaline Anti-Oxidant 4 stage Water Purifier"
            desc="Aura Oxy Alkaline Anti-oxidant 5 stage Wall mount Or under sink Water purifier system comes with ultra-filtration membrane as the main water treatment media. With help of multiple-stage pre-filtrations and post-filtrations, it can effectively remove all impurities of water."
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="5 Stage Purification"
                cardImg={f_img1}
                body=" This water filter system is designed with 5 stages of water filtration for household use, with UF membrane filter as the main filter and Precision PP cotton filter and Coconut activated carbon filter, an alkaline filter."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Easy Wall Mount"
                cardImg={Img3}
                body="Aura Oxy Alkaline Anti-oxidant 5 stage Wall mount can be mounted on the wall very easily or it can be fitted under the sink also which helps to save the space consumptions in the kitchen"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="No Electric consumption"
                cardImg={Img1}
                body="This unit can be easily adjusted to the preffered purification setting for your living area. Using these setting provides optimum performance and comfort. There are High low and medium modes for adjustment"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Great range of TDS"
                cardImg={f_img4}
                body="Both the Advance Photo Catalytic Oxidation (PCO) and Scalable Oxydation work to destroy biological contaminates as well as odors, bactria and viruses. PCO Cells have been proven to reduce dangerious pathogerms. "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Electricity
              </div>
              <div className="col-lg-9 border-table p-3">Not required</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Purification{" "}
              </div>
              <div className="col-lg-9 border-table p-3">5 Stage filters</div>
              <div className="col-lg-3 border-table p-3 fw-bold">P W O</div>
              <div className="col-lg-9 border-table p-3">200L per hour</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Purification efficiency
              </div>
              <div className="col-lg-9 border-table p-3">High</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">
                41.2 x 10.3 x 33.3 Centimeters
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">2.5 kgs</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Counter_top;
