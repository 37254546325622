import React from "react";
import sample from "../img/sample.jpg";
import ThumbCarousel from "../components/ThumbCarousel";

const Product = ({
  Img1,
  Img2,
  Img3,
  brand = "Brand name",
  img = sample,
  productName = "Product Name",
  model = "Model Name",
  desc = "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, cum, voluptatem necessitatibus maxime doloribus cupiditate accusantium adipisci, aperiam ipsa reprehenderit vel nostrum error sequi. Quas veritatis nulla esse? Quos, pariatur.",
  imgLink = "#",
  learnMoreLink = "#",
}) => {
  return (
    <div className="">
      <div className="container product">
        <div className="row">
          {/*  IMAGE SLIDER */}
          <div className="col-lg-6 col-md-12 px-0">
            {/*  THUMB CAROUSEL IMPORTED */}
            <ThumbCarousel img1={Img1} img2={Img2} img3={Img3} />
          </div>

          {/*  SHORT DESCRIPTION */}
          <div className=" text-box col-lg-6 col-md-12 px-lg-5  d-flex align-items-center py-4 ">
            <div className="col-lg-10">
              <h2 className="text-left fw-bolder mb-3">{brand}</h2>
              <h6 className="mb-5">Model : {model}</h6>
              <h2>{productName}</h2>
              <p className="mt-4 text-muted lh-lg">{desc}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
