import React from "react";

import sample from "../img/sample.jpg";
import Celogo from "../img/CE-LOGO.png";

const Product = ({
  brand = "Brand name",
  img = sample,
  productName = "Product Name",
  model = "Model Name",
  desc = "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, cum, voluptatem necessitatibus maxime doloribus cupiditate accusantium adipisci, aperiam ipsa reprehenderit vel nostrum error sequi. Quas veritatis nulla esse? Quos, pariatur.",
  imgLink = "#",
}) => {
  return (
    <div className="">
      <div className="container product">
        <div className="row">
          <div className="col-lg-6 col-md-12 px-0">
            <a href={imgLink}>
              <img src={img} className="img-fluid " alt="Sample Image" />
            </a>
          </div>

          <div className=" text-box col-lg-6 col-md-12 px-lg-5  d-flex align-items-center py-4 ">
            <div className="col-lg-10">
              <h2 className="text-left fw-bolder mb-3">{brand}</h2>
              <h6 className="mb-5">Model : {model}</h6>
              <h2>{productName}</h2>
              <p className="lh-lg">{desc}</p>
              <a href={imgLink} className="btn btn-danger mt-5">
                Learn More
              </a>
              <a
                href="/contact#contactForm"
                className="btn btn-outline-danger mt-5 ms-3"
              >
                Send an enquiry
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
