import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardRelatedProducts from "./CardRelatedProducts";
import r_img1 from "../img/product_img_related_product/img1.png";
import r_img2 from "../img/product_img_related_product/img2.png";
import r_img3 from "../img/product_img_related_product/img3.png";
import r_img4 from "../img/product_img_related_product/img4.png";
import r_img5 from "../img/product_img_related_product/img5.png";
import r_img6 from "../img/product_img_related_product/img6.png";
import r_img7 from "../img/product_img_related_product/img7.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CarouselRelatedProducts = (props) => {
  return (
    <div>
      <div className="m-auto">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-20-px"
        >
          <div>
            <CardRelatedProducts
              cardImg={r_img1}
              title="Aura Oxy - ZE-5W"
              body="Oxygen Concentrator 5ltm"
              imgLink="/ze5w"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img2}
              title="Aura Oxy - ZM-5W"
              body="Oxygen Concentrator 5ltm Touch Screen"
              imgLink="/zm5w"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img3}
              title="Aura Oxy - Canta"
              body="Oxygen Concentrator 8ltm Heavy Duty"
              imgLink="/canta"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img4}
              title="Aura Oxy - ZY-10ZW"
              body="Oxygen Concentrator 10ltm"
              imgLink="/zy10zw"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img5}
              title="Aura Air - Portable"
              body="Air Purifier with Ozone & Plazma Filtaration"
              imgLink="/air_ozone"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img6}
              title="Aura Air - Sterilizer"
              body="Air Sterilizer For Large Space"
              imgLink="/air_big"
            />
          </div>
          <div>
            <CardRelatedProducts
              cardImg={r_img7}
              title="Aura Air - Mask"
              body="Deluxe Smart Mask With Air Purification"
              imgLink="/smart_mask"
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselRelatedProducts;
