import React from "react";
import { Jumbotron, Button } from "reactstrap";
import Circle from "../thirdParty/circle";
import FloatingCubes from "../thirdParty/FloatingCubes";
import Type from "../thirdParty/Type";
import Heroimg from "../img/hero_collage.png";

const Hero = (props) => {
  return (
    <div className="section hero d-flex align-items-center">
      <Circle />
      <FloatingCubes />
      <Jumbotron className="text-white ">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12  ">
            <div className="row ">
              <div className="col ms-4 ">
                <h1 className="display-2 fw-bold hero-top-margin">
                  <span>Aura Medical</span>
                  <span className="fw-light display-4"> Limited</span>
                </h1>
                <div className="dash1 mt-3"></div>
                <h4 className="mt-2 pe-4">
                  Welcome to Aura Medical Limited, where we are dedicated to transforming health care with a patient-centric approach.
                  
                </h4>
                <div className='mt-4'>
                <h4 >
                  We Provide
                  <Type />
                </h4>
                
                </div>
                
              </div>
            </div>
          </div>
          <center className="col-lg-7 col-md-12 align-items-end ">
            <h1 className=" text-left pt-5 fw-light px-5 mb-0">
              <span className="fw-bold">Medical Grade</span> Oxygen
              Concentrators
            </h1>
            <img src={Heroimg} alt="Models" className="  pt-5" />
          </center>
        </div>
      </Jumbotron>
    </div>
  );
};

export default Hero;
