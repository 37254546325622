import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  return (
    <div>
      <Navbar />
      <div className="">
        <div className="container-fluid contact-header mt-5 pt-5">
          <h1 className="display-1 text-center fw-bold mt-5 pt-4">
            Contact Us
          </h1>
        </div>

        <div className="container-fluid map-form p-5">
          <div className="row ms-lg-5 ps-lg-3">
            <div className="col-xl-6 p-0 contact-map-box ">
              <div className="mapouter ms-auto me-4">
                <div className="gmap_canvas">
                  <iframe
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.4739411517967!2d77.15577481506708!3d28.52547098246022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e7870c02baf%3A0xb8b0956caa0eaeea!2sPocket%207%2C%20Sector%20B%2C%20Vasant%20Kunj%2C%20New%20Delhi%2C%20Delhi%20110070!5e0!3m2!1sen!2sin!4v1625050905861!5m2!1sen!2sin"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.9784535550352!2d77.16298352611716!3d28.510295975731726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e6ea651003f%3A0xe91c624ee1cb2f83!2sPocket%202%2C%20Sector%20D%2C%20Vasant%20Kunj%2C%20New%20Delhi%2C%20Delhi%20110070!5e0!3m2!1sen!2sin!4v1700371705916!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>

                  <a href="https://www.whatismyip-address.com/divi-discount/"></a>
                </div>
                <style></style>
              </div>
            </div>
            <div className="col-xl-6 contact-form-box pl-lg-5">
              <h4>Corporate Office</h4>
              <div className="dash mb-4"></div>
              <div className="">
                <div className="address">
                  <p className="mb-0">
                  DDA Unit No. 19, GF,
                  </p>
                  <p className="mb-0">SECTOR-D, POCKET- 2, VASANT KUNJ,</p>
                  <p className="mb-3"> NEW DELHI - 110070, India</p>
                </div>
                <div className="email">rekha@amdl.in || saurabh@amdl.in</div>

                <div className="mobile">+ 91- 9810059402</div>
                <div id="contactForm">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
