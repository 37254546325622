import React from "react";
import {
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import myCardImg from "../img/product_img_landing/air_purifier_2.jpg";

const CardRelatedProducts = ({
  cardImg = myCardImg,
  body = "This is card body text.",
  title = "Card Title",
  imgLink = "#",
}) => {
  return (
    <div>
      <Card className="related-product-card">
        <CardImg top width="100%" src={cardImg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">{title}</CardTitle>
          <CardText className="text-muted">{body}</CardText>

          <div className="d-grid">
            <a href={imgLink} className="btn btn-outline-primary ">
              More Details
            </a>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardRelatedProducts;
