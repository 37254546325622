import React from "react";
import Title1 from "./Title1";
import certificate1 from "../img/certificte1.png";
import certificate2 from "../img/certificate2.png";
import certificate3 from "../img/certificate3.png";
const Certefication = ({}) => {
  return (
    <div className="certificate container">
      <Title1 mainTitle="OUR" subTitle="CERTIFICATIONS" />
      <div className="row">
        <div className="col-lg-4">
          <img src={certificate1} alt="Certificate" className="shadow" />
        </div>
        <div className="col-lg-4">
          <img src={certificate2} alt="Certificate" className="shadow" />
        </div>
        <div className="col-lg-4">
          <img src={certificate3} alt="Certificate" className="shadow" />
        </div>
      </div>
    </div>
  );
};

export default Certefication;
