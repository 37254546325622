import React from "react";
import {
  FaPhone,
  FaMobileAlt,
  FaEnvelopeOpenText,
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaHeart,
} from "react-icons/fa";
import { NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer text-white pt-5 mt-5 pb-4">
      <div className="container ">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-2">
            <ul className="list-unstyled">
              <h4>Aura Oxy</h4>
              <li>
                <a href="/ze5w">ZE-5W</a>
              </li>
              <li>
                <a href="/zm5w">ZM-5W</a>
              </li>
              <li>
                <a href="/canta">Canta 8ltm</a>
              </li>
              <li>
                <a href="/zy10zw">ZY-10ZW</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-2">
            <ul className="list-unstyled">
              <h4>Aura Air</h4>
              <li>
                <a href="/air_ozone">Aura Air Purifier</a>
              </li>
              <li>
                <a href="/air_big">Aura Air Sanitizer</a>
              </li>
              <li>
                <a href="/smart_mask">Delux Smart Mask</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-4">
            <h4 className="">Connect</h4>
            <ul className="list-unstyled">
              <li>
                {/*<p>
                  AURA MEDICAL DEVICES LIMITED <br />
                  CIN: U33110DL2012PLC242345
                </p>*/}
                <p>
                  DDA Unit No. 19, GF, Sector D,
                  POCKET- 2, VASANT KUNJ, NEW DELHI 110070, India{" "}
                </p>
              </li>
              <li>
                <p>rekha@amdl.in || saurabh@amdl.in </p>
              </li>
              <li>
                <p>+ 91- 9810059402</p>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h4 className="">Follow Us</h4>
            <div className="social-icons ">
              <h2>
                <a href="https://www.facebook.com/AMDL.STORE" target="_blank">
                  <FaFacebookSquare className="text-white" className="me-2" />
                </a>
                <a
                  href="https://www.instagram.com/auramedicaldevices/"
                  target="_blank"
                >
                  <FaInstagramSquare className="text-white" className="me-2" />
                </a>
                <a href="#">
                  <FaYoutubeSquare className="text-white" className="me-2" />
                </a>
              </h2>
            </div>
          </div>
          <div className="footer-bottom mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 footer-copyright">
                  <p>&copy; 2021 amdl.in All rights reserved.</p>
                </div>
                <div className="col-md-6 footer-copyright text-right">
                  <p className="d-flex justify-content-end">
                    Made with <FaHeart className="mx-2" />
                    <a
                      href="https://amitavo.netlify.app"
                      className="d-inline p-0 pl-2"
                    >
                      Amitavo.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
