import React from "react";
import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const [state, handleSubmit] = useForm("xrgrlwbq");
  if (state.succeeded) {
    return <h3>Thanks for Contacting Us. We will call you soon!</h3>;
  }
  return (
    <div className="contact-from mt-3">
      {/* Form submitted to niranjan@amdl.in through Formspree */}
      <form onSubmit={handleSubmit}>
        <div className="name col-lg-8 mb-2">
          <input
            className="form-control "
            type="text"
            name="name"
            id="name"
            placeholder="Your Name.."
          />
        </div>
        <div className="mobile col-lg-8 mb-2">
          <input
            className="form-control c"
            type="number"
            name="mobile"
            id="mobile"
            placeholder="Your Mobile number.."
          />
        </div>
        <div className="col-lg-8 mb-4">
          <textarea
            name="message"
            id="message"
            cols="19"
            rows="3"
            placeholder="Enter your message..."
            className="form-control"
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={state.submitting}
          className="btn btn-success"
        >
          Send MSG
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
