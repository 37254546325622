import React from "react";

const Title2 = ({ mainTitle = "Main Title", subTitle = "Sub Title" }) => {
  return (
    <div className="my-5 title1">
      <center>
        <div className="dash mb-3"></div>
      </center>
      <h4 className="text-center mb-1">{subTitle}</h4>
      <h1 className="text-center display-5 fw-bolder">{mainTitle}</h1>
    </div>
  );
};

export default Title2;
