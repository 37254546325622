import React from "react";
import { NavLink } from "react-router-dom";

const Dealer = () => {
  return (
    <NavLink to="/new-dealer">
      <div className="dealer">
        <h6 className="pt-2">Apply For Dealership</h6>
      </div>
    </NavLink>
  );
};

export default Dealer;
