import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/countertop.jpg";
import Img2 from "../../img/product_Img/countertop1.jpeg";
import Img3 from "../../img/product_Img/countertop3.jpg";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/counter_top/01.png";
import f_img2 from "../../img/product_img_features/counter_top/02.png";
import f_img3 from "../../img/product_img_features/counter_top/03.png";
import f_img4 from "../../img/product_img_features/counter_top/04.png";

const Counter_top = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Oxy"
            model="AO-CT-W"
            productName="Countertop Alkaline Anti-Oxidant 4 stage Water Purifier"
            desc="Aura Oxy - Countertop alkaline water purifier with filter replace time reminder. Adopt ultra-filtration membrane as the main water treatment media. With help of multiple-stage pre-filtrations and post-filtrations, an Aura Oxy alkaline water purifier can effectively remove suspended sediment, rust, residual chlorine, organic matter, colloid, bacteria, and viruses in drinking water and improve water taste."
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="4 Stage Purification"
                cardImg={f_img1}
                body=" This water filter system is designed with 4 stages of water filtration for household use, with UF membrane filter as the main filter and Precision PP cotton filter and Coconut activated carbon filter, an alkaline filter."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Filter Replacement Reminder"
                cardImg={f_img2}
                body="Aura-Oxy Counter Top Alkaline water purifier comes with Digital Display which gives a reminder whenever any filter is tobe replaced."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Light weight and portable"
                cardImg={f_img3}
                body="This unit can be easily adjusted to the preffered purification setting for your living area. Using these setting provides optimum performance and comfort. There are High low and medium modes for adjustment"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Great range of TDS"
                cardImg={f_img4}
                body="Both the Advance Photo Catalytic Oxidation (PCO) and Scalable Oxydation work to destroy biological contaminates as well as odors, bactria and viruses. PCO Cells have been proven to reduce dangerious pathogerms. "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Electricity
              </div>
              <div className="col-lg-9 border-table p-3">Not required</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Purification{" "}
              </div>
              <div className="col-lg-9 border-table p-3">4 Stage filters</div>
              <div className="col-lg-3 border-table p-3 fw-bold">P W O</div>
              <div className="col-lg-9 border-table p-3">200L per hour</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Purification efficiency
              </div>
              <div className="col-lg-9 border-table p-3">High</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">
                25 x 21 x 35 Centimeters
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">5.26 kgs</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Counter_top;
