import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/air_purifier_1-1.jpg";
import Img2 from "../../img/product_Img/air_purifier_1-4.png";
import Img3 from "../../img/product_Img/air_purifier_1-5.png";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/air_purifier_small/img1.png";
import f_img2 from "../../img/product_img_features/air_purifier_small/img2.png";
import f_img3 from "../../img/product_img_features/air_purifier_small/img3.png";
import f_img4 from "../../img/product_img_features/air_purifier_small/img4.png";

const Air_purifier_small = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Air"
            model="Plazma & Ozone Purifier"
            productName="Plazma & Ozone Air purifier"
            desc="Aura Air is the ideal solution for most active spaces in our home, such as living rooms, recreation rooms and bed rooms. It is also very much sutaible in Business areas like conference rooms, waiting areas aswell as personal cabins.It works with proven OptiPure ozone technology to rapidly oxidize the worst odors, freshen, energize and purify the air you breathe."
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="Electrostatic Filtration"
                cardImg={f_img1}
                body="An advanced electrostatic filter is included with the air purifier to help kepp the purifier clean while at the same time filtering out particles. Unlike HEPA filter this is washable and can be used over and over again."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Needlepoint Ionization"
                cardImg={f_img2}
                body="It generates a continous stream of millions of negetative ions that circulate thoughout the home. These ions charge particles such as dust or bactria causing them to attack other particles and clump together."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Scalable Purification"
                cardImg={f_img3}
                body="This unit can be easily adjusted to the preffered purification setting for your living area. Using these setting provides optimum performance and comfort. There are High low and medium modes for adjustment"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Advanced Photo Catalytic"
                cardImg={f_img4}
                body="Both the Advance Photo Catalytic Oxidation (PCO) and Scalable Oxydation work to destroy biological contaminates as well as odors, bactria and viruses. PCO Cells have been proven to reduce dangerious pathogerms. "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold ">
                Unit Size{" "}
              </div>
              <div className="col-lg-9 border-table p-3">
                12″ x 9″ x 10.5″ / 10.2 lbs{" "}
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">Coverage </div>
              <div className="col-lg-9 border-table p-3">
                {" "}
                250 – 3000 square feet
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">Input </div>
              <div className="col-lg-9 border-table p-3">
                120-240VAC 50/60Hz 2.5 A{" "}
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                PCO Cell Output{" "}
              </div>
              <div className="col-lg-9 border-table p-3">
                {" "}
                less than 0.02 ppm ozone{" "}
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Ionization{" "}
              </div>
              <div className="col-lg-9 border-table p-3"> 24-30 KV </div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Air_purifier_small;
