import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/Oxy_canta_8lt_1.jpg";
import Img2 from "../../img/product_Img/Oxy_canta_8lt_2.png";
import Img3 from "../../img/product_Img/Oxy_canta_8lt_3.png";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/canta_8lt/f2.png";
import f_img2 from "../../img/product_img_features/canta_8lt/f1.png";
import f_img3 from "../../img/product_img_features/canta_8lt/f3.png";
import f_img4 from "../../img/product_img_features/oxy_5lt_ze5w/f_4.png";

const Canta_8lt = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Canta"
            model="Canta - V8"
            productName="Oxygen Concentrator 8lpm Dual flow"
            desc="Canta V8 is both CE and FDA certificates, double flow, high purity oxygen 7/24 still working. Intelligent Self Diagnosis System provides light and sound alarming, with data indicating on LCD the detail failures, include: Over Heat, High Pressure, Power Failure, Atomizing Function (Nebulizer) "
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features m-auto">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <CardFeaturesProductPage
                title="Medical Grade Oxygen"
                cardImg={f_img1}
                body="It produces medical grade oxygen ranging form 93% to 96% of high oxygen concentration. This helps in high rate of recovery of the patients."
              />
            </div>
            <div className="col-lg-3">
              <CardFeaturesProductPage
                title="Portable & sleek Design"
                cardImg={f_img2}
                body="The Design of the Canta V8 is very strong and sturdy for heavy duty performance and use. You can use Canta V8 24 x 7 nonstop and it will never trip."
              />
            </div>
            <div className="col-lg-3">
              <CardFeaturesProductPage
                title="Dual Flow adoptor"
                cardImg={f_img3}
                body="The main motor of Canta is so powerfull that you two patients can use a single product simultaniosly. Its supplies pure oxygen to both of the patients"
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Oxygen Flow
              </div>
              <div className="col-lg-9 border-table p-3">
                8 L/Min (can be adjusted 1L - 8L/Min)
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Oxygen Concentration{" "}
              </div>
              <div className="col-lg-9 border-table p-3">
                93% +- 3% (0-5L/Min)
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Outlet Pressure
              </div>
              <div className="col-lg-9 border-table p-3"> 0.04-0.08 MPA</div>

              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">
                390 x 337 x 620 mm
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">24 kgs</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Canta_8lt;
