import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Dealer from "./components/Dealer";
import Whatsapp from "./components/Whatsapp";
import Product from "./components/Product";
import Title1 from "./components/Title1";
import Testimonial from "./components/Testimonial";
import Certefication from "./components/Certification";
import Footer from "./components/Footer";
import Aura_oxy_5lt from "./img/product_img_landing/aura_oxy_5lt_ready.jpg";
import Aura_oxy_5lt_touch from "./img/product_img_landing/aura_oxy_5ltr.jpg";
import canta_8lt from "./img/product_img_landing/canta_8ltr.jpg";
import Aura_oxy_10ltr from "./img/product_img_landing/aura_oxy_10ltr.jpg";
import Aura_air_small from "./img/product_img_landing/air_purifier_1.jpg";
import Aura_air_big from "./img/product_img_landing/air_purifier_2.jpg";
import Aura_smart_mask from "./img/product_img_landing/aura_mask_black.jpg";
import Aura_Oxy_5filter from "./img/product_img_landing/5filter.jpg";
import Aura_Oxy_countertop from "./img/product_img_landing/countertop.jpg";
import "./App.css";

const App = () => {
  return (
    <div className="App container-fluid p-0 cla">
      <Navbar />
      <Hero />
      <Dealer />
      <Whatsapp />
      <Title1 mainTitle="OUR" subTitle="PRODUCTS" />
      <Product
        // Aura Auxy Counter Top
        img={Aura_Oxy_countertop}
        brand="Aura Oxy"
        productName="Countertop Alkaline Anti-Oxidant 4 stage Water Purifier"
        model="AO-CT-W"
        desc="Aura Oxy - Countertop alkaline water purifier with filter replace time reminder. Adopt ultra-filtration membrane as the main water treatment media. With help of multiple-stage pre-filtrations and post-filtrations, an Aura Oxy alkaline water purifier can effectively remove suspended sediment, rust, residual chlorine, organic matter, colloid, bacteria, and viruses in drinking water and improve water taste."
        imgLink="/ao_ct_w"
      />
      <Product
        img={Aura_Oxy_5filter}
        brand="Aura Oxy"
        productName="Alkaline Anti-Oxidant 5 stage water Purifier"
        model="AO-WM-S"
        desc="Aura Oxy alkaline water filter use food-grade UF membrane, active carbon, and alkaline filter media, removing sand, mud, bacterial, virus, chlorine and so on."
        imgLink="/ao_wm_w"
      />
      <Product
        img={Aura_oxy_5lt}
        brand="Aura Oxy"
        productName="Oxygen Concentrator 5lpm"
        model="ZE-5W"
        desc="Aura Oxy 5LPM Oxygen Concentrator comes with Oil free compressor with life upto 5 years high grade imported molecular sieve with 10000/hrs life working capacity 24×7 produce high oxygen purity at max flow (93+-3%) high quality ABS body 
        Easy to operate 
        Medical Grade"
        imgLink="/ze5w"
      />
      <Product
        img={Aura_oxy_5lt_touch}
        brand="Aura Oxy"
        productName="Oxygen Concentrator 5lpm Touch Screen"
        model="ZM-5W"
        desc="Aura Oxy 5LPM Oxygen Concentrator comes with a sleek and ergonomic design, which makes it easier to store, move, and carry."
        imgLink="/zm5w"
      />
      <Product
        img={canta_8lt}
        brand="Aura Oxy"
        productName="Oxygen Concentrator 8lpm Heavy Duty"
        model="Canta"
        desc="Canta V8 is both CE and FDA certificates, double flow, high purity oxygen 7/24 still working. Intelligent Self Diagnosis System provides light and sound alarming."
        imgLink="/canta"
      />
      <Product
        img={Aura_oxy_10ltr}
        brand="Aura Oxy"
        productName="Oxygen Concentrator 10lpm"
        model="ZY-10ZW"
        desc="Aura Oxy 10LPM Oxygen Concentrator comes with a sleek and ergonomic design, which makes it easier to store, move, and carry. It is one of the quietest and most energy-efficient devices with no filter to maintain"
        imgLink="/zy10zw"
      />
      <Product
        img={Aura_air_small}
        brand="Aura Air"
        productName="Air Purifier with Ozone & Plazma Filtaration"
        model="Portable "
        desc="Aura Air is the ideal solution for most active spaces in our home, such as living rooms, recreation rooms and bed rooms. It is also very much sutaible in Business areas. Protect your family from  contagious bacteria and viruses breath fresh for healthy life 
        99.99% purification
        No extra filter cost
        Coverage upto 3000/sqft"
        imgLink="/air_ozone"
      />
      <Product
        img={Aura_air_big}
        brand="Aura Air"
        productName="Air Sterilizer "
        model="Air Sterilizer "
        desc="Aura Air sanitizer is a heavy-Duty air purifier consisting of various layers of filtrations. There a filters like HEPA Filter, Activated Carbon filter, Cold-Catalyst filter, Nagative Ions and also UV lamp. It also consist of Air Monitoring Lamp"
        imgLink="/air_big"
      />
      <Product
        img={Aura_smart_mask}
        brand="Aura Air"
        productName="Deluxe Smart Mask "
        model="Smart Mask "
        desc="Aura Air mask is designed for comfortable protection against dust, pollen,fumes, common airborne irritants, and other non-oil based particles. It will help you breathe in a cleaner, healthier way.
        Our dust mask implements the design preventing the pollution mask from slipping off. "
        imgLink="/smart_mask"
      />

      {/* <Testimonial /> */}
      <Certefication />
      <Footer />
    </div>
  );
};

export default App;
