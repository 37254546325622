import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Title2 from "../components/Title2";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="about-us">
        <div className="container-fluid contact-header mt-5 pt-5">
          <h1 className="display-1 text-center fw-bold mt-5 pt-4">About Us</h1>
        </div>

        <div className="container pt-5">
          <Title2 subTitle="Our" mainTitle="Company Profile" />

          <div
            className="container border  mt-3 mb-5 "
            style={{ borderRadius: "20px", padding: "50px" }}
          >
            
                <h4 className="lh-lg">
                Welcome to Aura Medical Limited, a distinguished importer and distributor based in India, dedicated to revolutionizing the healthcare landscape. With a robust foundation in B2B relations, we specialize in providing exemplary products and services to medical institutions and our stakeholders. We strongly believe in advancing healthcare and fostering a healthier and safer world.</h4>
                <h4 className="lh-lg">
                  Additionally, recognizing the critical importance of manufacturing standards, we provide meticulous research, development, and trial assistance for sponsors, along with manufacturing unit facility audit services. This includes audits for laboratories, tablets, capsules, MAB, vaccines, and injectables, ensuring compliance so that the production processes meet the highest quality benchmarks.</h4>
                <h4 className="lh-lg">
                  Our commitment to excellence spans multiple medical domains and therapeutic areas, including orthopedic, surgical, and dental implants, IVD reagents, PPE Kits, N95 Masks, Nitrile and Examination gloves, and alcohol-based sanitizers. We take pride in providing seamless access to high-quality medical supplies, ensuring the safety of both healthcare providers and patients.</h4>
                <h4 className="lh-lg">
                  At Aura Medical Limited we go beyond mere import and distribution. Our expertise includes Clinical Monitoring and Clinical Trial Management Services, which help to raise healthcare practice standards. In addition, we provide comprehensive Audit and Licensing services to ensure compliance with the D&C Act of 1945, the NDCT rules of 2019, and WHO-GMP standards for both medical devices and generic medicines.</h4>
                <h4 className="lh-lg">
                  Recognizing the critical importance of manufacturing GMP standards, we provide meticulous manufacturing and facility audit services. This includes audits of laboratories, tablets, and capsules, as well as ensuring that production processes meet the highest quality standards.

                </h4>
            
          </div>
        </div>
        <div className="our-mission container">
          <div>
            <Title2 subTitle="Why" mainTitle="Us" />
            <div
              className="container border  mt-4 mb-5 "
              style={{ borderRadius: "20px", paddingTop: "30px" }}
            >
              <h4 className="p-4 lh-lg">
              <h2 >
              Patient-centric 
              </h2>
                 At AURA Medical Limited, our commitment is to develop and provide futuristic solutions that not only improve health outcomes but also prioritize patients' comfort and well-being. Putting patient care at the forefront of every action, we align every activity to make advanced healthcare available, affordable, and accessible. 
              </h4>
            </div>
          </div>

          <div>
            <Title2 subTitle="Our" mainTitle="Core Values" />
            <div
              className="container border  mt-4 mb-5 "
              style={{ borderRadius: "20px", paddingTop: "30px" }}
            >
              <h4 className="p-4 lh-lg">
              <h2>Availability</h2>

                  Despite significant advances in research and development, as well as rapidly changing technology, many patients' health concerns remain unaddressed. This warrants significant investment in understanding the healthcare challenges of patients. Thus, at Aura, we take our R&D operations with the utmost dedication and diligence to make solutions available to patients. 
                  
                  <h2 className="mt-3">Affordability</h2>
                  

                  We understand the financial challenges that individuals and healthcare systems face. That's why we strive to deliver high-quality medical devices and healthcare services at reasonable and affordable prices which help healthcare providers reduce the cost of providing healthcare. We firmly believe that health should never be compromised due to financial constraints.
                  <h2 className="mt-3">Accessible</h2>
                  

                  Making affordable healthcare available on the market is insufficient. It is necessary to ensure that they are accessible to people, even in the most remote areas. As a result, we are always eager to collaborate with institutions in order to reach the farthest reaches of the globe.
                  <h2 className="mt-3">Why 3 A's?</h2>                 
                  Availability, accessibility, and affordability are deeply interlinked when it comes to fostering sustainability and patient care in the healthcare industry. For instance, the availability of a healthcare service in the remotest area might not guarantee a positive outcome if it is too expensive for the underserved masses. Similarly, making affordable health care services and products would be futile if they were not made accessible to people irrespective of gender, caste, and ethnicity.
              </h4>
            </div>
          </div>
          <div>
            <Title2 subTitle="Our" mainTitle="Brands" />
            <div
              className="container border  mt-4 mb-5 "
              style={{ borderRadius: "20px", paddingTop: "30px" }}
            >
              <h4 className="p-4 lh-lg">
              <h2 className="text-center">Aura Oxy®</h2>
              <h2 className="mt-3 text-center">Aura Air™</h2>
   
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
