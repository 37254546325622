import React from "react";
import CardFeaturesProductPage from "../../components/CardFeaturesProductPage";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Product_ProductPage from "../../components/Product_ProductPage";
import Title2 from "../../components/Title2";
import Img1 from "../../img/product_Img/air_purifier_2-1.png";
import Img2 from "../../img/product_Img/air_purifier_2-2.JPG";
import Img3 from "../../img/product_Img/air_purifier_2-3.JPG";
import CarouselRelatedProducts from "../../components/CarouselRelatedProducts";
import f_img1 from "../../img/product_img_features/air_purifier_big/Group.png";
import f_img2 from "../../img/product_img_features/air_purifier_small/img2.png";
import f_img3 from "../../img/product_img_features/air_purifier_small/img3.png";
import f_img4 from "../../img/product_img_features/air_purifier_small/img4.png";

const Air_purifier_big = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <div className="m-nav ">
          <Product_ProductPage
            Img1={Img1}
            Img2={Img2}
            Img3={Img3}
            brand=" Aura
          Air"
            model="Air Sanitizer"
            productName="Aura Air Sanitizer"
            desc="Aura Air sanitizer is a heavy-Duty air purifier consisting of various layers of filtrations. There a filters like HEPA Filter, Activated Carbon filter, Cold-Catalyst filter, Nagative Ions and also UV lamp. It also consist of Air Monitoring Lamp"
          />
        </div>

        {/*MAIN FEATURES*/}
        <div className="features ">
          <Title2 mainTitle="Features" subTitle="Main" />
          <div className="row justify-content-center">
            <div className="col">
              <CardFeaturesProductPage
                title="HEPA Filtration"
                cardImg={f_img1}
                body="It removes particulates such as micro-organisms from the air. With HEPA Filters Aura Air Sanitizer  have a minimum filtration efficiency of 99.99% against airborne particles 0.3 microns in size."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Needlepoint Ionization"
                cardImg={f_img2}
                body="It generates a continous stream of millions of negetative ions that circulate thoughout the home. These ions charge particles such as dust or bactria causing them to attack other particles and clump together."
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Scalable Purification"
                cardImg={f_img3}
                body="This unit can be easily adjusted to the preffered purification setting for your living area. Using these setting provides optimum performance and comfort. There are High low and medium modes for adjustment"
              />
            </div>
            <div className="col">
              <CardFeaturesProductPage
                title="Advanced Photo Catalytic"
                cardImg={f_img4}
                body="Both the Advance Photo Catalytic Oxidation (PCO) and Scalable Oxydation work to destroy biological contaminates as well as odors, bactria and viruses. PCO Cells have been proven to reduce dangerious pathogerms. "
              />
            </div>
          </div>
        </div>

        {/*SPECIFICATIONS*/}
        <div className="specifications">
          <Title2 mainTitle="Specifications" subTitle="All" />
          <div className="table  ">
            <div className="row p-4 w-75 m-auto">
              <div className="col-lg-3 border-table p-3 fw-bold">
                Rated Voltage
              </div>
              <div className="col-lg-9 border-table p-3">220V-50Hz</div>
              <div className="col-lg-3 border-table p-3 fw-bold">CARD </div>
              <div className="col-lg-9 border-table p-3">
                700 meter cube per hour
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Formaldehyde{" "}
              </div>
              <div className="col-lg-9 border-table p-3">
                150 meter cube per hour
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Purification efficiency
              </div>
              <div className="col-lg-9 border-table p-3">Highhly</div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Product size
              </div>
              <div className="col-lg-9 border-table p-3">
                690 x 530 x 330 mm
              </div>
              <div className="col-lg-3 border-table p-3 fw-bold">
                Gross Weight
              </div>
              <div className="col-lg-9 border-table p-3">22.5 kgs</div>
            </div>
          </div>
        </div>

        {/*RELATED PRODUCTS*/}

        <div className="related-products">
          <Title2 mainTitle="Products" subTitle="Related" />
          <CarouselRelatedProducts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Air_purifier_big;
